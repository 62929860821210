<template>
  <div>
    <ValidationObserver v-slot="{ invalid }">
      <div class="router-view-container">
        <section class="flex items-center space-x-4 mb-5">
          <vs-button @click="$router.go(-1)" border size="large" class="flex-shrink-0">
            <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
          </vs-button>
          <h1 class="page_title">{{ $route.meta.title }}</h1>
        </section>

        <main v-if="is_API_loaded || isNew" class="space-y-5">
          <!-- <h1 v-if="!isNew" class="text-xl font-medium text-gray-text_dark">{{ data.title }}</h1> -->

          <!-- 新增後顯示 -->
          <transition name="fade">
            <section v-if="alertAfterCreated">
              <vs-alert color="success" gradient>
                <template #icon>
                  <svg-icon icon-class="success" className="xxl"></svg-icon>
                </template>
                <template #title> {{ alertTitle }} 已新增成功 </template>
              </vs-alert>
            </section>
          </transition>

          <!-- 2. -->
          <section class="flex flex-wrap md:flex-nowrap space-y-4 md:space-y-0 md:space-x-4">
            <!-- 2.1.1 -->
            <div class="w-full md:w-14/20 space-y-4 flex-shrink-0">
              <article class="box">
                <div>
                  <h4 class="h4 mb-1">活動名稱 *</h4>
                  <ValidationProvider tag="div" class="relative" name="活動名稱" rules="required|max:50" v-slot="{ errors }">
                    <vs-input v-model="data.title"></vs-input>

                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>

                <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <h4 class="h4 mb-1">開始時間 *</h4>
                    <vs-input type="datetime-local" v-model="_vdate" :min="_today" />
                  </div>

                  <div>
                    <h4 class="h4 mb-1">結束時間 *</h4>
                    <vs-input :disabled="!data.vdate" type="datetime-local" v-model="_edate" :min="data.vdate" />
                  </div>
                </div>

                <div>
                  <h4 class="h4 mb-1">活動對象 *</h4>
                  <vs-select v-if="vip.length" v-model="data.smvno">
                    <vs-option value="不限對象" label="不限對象">不限對象</vs-option>
                    <vs-option value="全體會員" label="全體會員">全體會員</vs-option>
                    <vs-option v-for="vip in vip" :key="vip.smvno" :value="vip.smvno" :label="vip.title">{{ vip.title }}</vs-option>
                  </vs-select>
                </div>
              </article>
              <article class="box">
                <div>
                  <div class="flex items-center justify-between mb-1">
                    <h4 class="h4">單筆消費門檻 *</h4>
                    <p class="text-gray-400">※ 無消費門檻請填入0</p>
                  </div>

                  <ValidationProvider
                    tag="div"
                    class="relative"
                    name="單筆消費門檻"
                    rules="required|integer|min_value:0"
                    v-slot="{ errors }"
                  >
                    <div class="flex items-center border border-gray-border rounded-xl pl-3 space-x-1 overflow-hidden h-10">
                      <div>
                        <span>NT$</span>
                      </div>
                      <input type="number" class="w-full h-full" v-model.number="data.pays" />
                    </div>
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>

                <div>
                  <h4 class="h4 mb-1">折扣類型 *</h4>
                  <div class="flex items-start">
                    <vs-radio @input="handleRadio" success v-model="data.dtype" :val="0">百分比</vs-radio>
                    <vs-radio @input="handleRadio" success v-model="data.dtype" :val="1">固定金額</vs-radio>
                  </div>
                </div>

                <div>
                  <h4 class="h4 mb-1">折扣金額 *</h4>
                  <ValidationProvider
                    v-if="data.dtype === 0"
                    tag="div"
                    class="relative"
                    name="折扣金額"
                    rules="required|integer|min_value:1|max_value:99"
                    v-slot="{ errors }"
                  >
                    <div class="flex items-center border border-gray-border rounded-xl px-3 overflow-hidden h-10">
                      <input type="number" class="w-full h-full" v-model.number="data.discount" />
                      <div>
                        <p class="w-14">% OFF</p>
                      </div>
                    </div>
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                  <ValidationProvider
                    v-if="data.dtype === 1"
                    tag="div"
                    class="relative"
                    name="折扣金額"
                    rules="required|integer|min_value:1"
                    v-slot="{ errors }"
                  >
                    <div class="flex items-center border border-gray-border rounded-xl pl-3 space-x-1 overflow-hidden h-10">
                      <div>
                        <span>NT$</span>
                      </div>
                      <input type="number" class="w-full h-full" v-model.number="data.discount" />
                    </div>
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
              </article>
            </div>

            <!-- 2.2 -->
            <div class="flex-grow space-y-4">
              <div class="box">
                <h3 class="h3">摘要</h3>
                <div class="space-y-2">
                  <p class="list_item" v-if="data.pays > 0">
                    單筆消費(不含運費)滿 NT${{ data.pays | currency }}元，即自動套用
                    <span v-if="data.dtype === 0">{{ _discount }}折</span>
                    <span v-if="data.dtype === 1">NT${{ data.discount | currency }}元</span>
                    折扣。
                  </p>
                  <p class="list_item" v-else>
                    所有訂單不限金額皆自動套用
                    <span v-if="data.dtype === 0">{{ _discount }}折</span>
                    <span v-if="data.dtype === 1">NT${{ data.discount | currency }}元</span>
                    折扣。
                  </p>
                  <p class="list_item" v-if="data.smvno === '不限對象'">此優惠活動適用於 所有人。</p>
                  <p class="list_item" v-else-if="data.smvno === '全體會員'">此優惠活動只適用於 全體會員。</p>
                  <p class="list_item" v-else>此優惠活動只適用於 {{ _membership }}。</p>
                </div>
              </div>
            </div>
          </section>

          <hr />

          <section class="flex items-center justify-between">
            <vs-button @click="delete_dialog = true" v-if="!isNew" size="large" color="danger" border>
              <span class="font-medium">刪除行銷活動</span>
            </vs-button>
            <router-link v-else :to="{ name: 'storewide_discounts' }">
              <vs-button size="large" border>
                <span class="font-medium">取消</span>
              </vs-button>
            </router-link>

            <vs-button size="large" ref="saveBtn" :disabled="invalid" @click="save(invalid)">
              <span v-if="isNew" class="font-medium">新增</span>
              <span v-else class="font-medium">儲存</span>
            </vs-button>
          </section>
        </main>
      </div>
    </ValidationObserver>

    <!-- 骨架屏 -->
    <main v-if="!is_API_loaded && !isNew" class="router-view-container space-y-4">
      <section class="flex items-center space-x-4 justify-between">
        <skeleton width="48px" />

        <div v-if="!isNew" class="flex lg:w-full items-center justify-between">
          <skeleton class="hidden lg:block" width="50%" height="40px" />
          <div class="flex items-center space-x-2">
            <skeleton width="82px" />
            <skeleton width="82px" />
          </div>
        </div>
      </section>
      <skeleton v-if="!isNew" class="lg:hidden" width="80%" height="40px" />

      <section class="flex flex-wrap md:flex-nowrap space-y-4 md:space-y-0 md:space-x-4">
        <div class="w-full md:w-14/20 space-y-4">
          <article class="box">
            <skeleton />

            <div>
              <skeleton height="200px" />
            </div>
          </article>

          <article class="box">
            <skeleton />
          </article>
        </div>

        <div class="flex-grow space-y-4">
          <div class="box">
            <skeleton />
            <skeleton />
            <skeleton />
          </div>
          <div class="box">
            <skeleton />
            <skeleton />
            <skeleton />
          </div>
        </div>
      </section>

      <hr />

      <section class="flex items-center justify-between">
        <skeleton width="82px" />
        <skeleton width="82px" />
      </section>
    </main>

    <!-- 刪除全店優惠 dialog -->
    <vs-dialog overflow-hidden blur v-model="delete_dialog" not-center>
      <template #header>
        <h1 class="text-base font-medium text-gray-text_dark">是否要刪除此行銷活動？</h1>
      </template>

      <div class="mb-3">
        <p class="text-sm text-gray-text_light">刪除的行銷活動無法復原，確定仍要繼續？</p>
      </div>

      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="delete_dialog = false">取消</vs-button>
          <vs-button @click="deleteFunc" size="large" transparent color="danger" ref="deleteFuncBtn">刪除</vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
import dateTimeValidation from '@/assets/mixins/dateTimeValidation.js'
export default {
  name: 'storewide_discount',
  mixins: [dateTimeValidation],
  data() {
    return {
      is_API_loaded: false,
      isNew: false,
      alertTitle: '',
      alertAfterCreated: false,
      delete_dialog: false,
      data: {
        title: '',
        smvno: '不限對象',
        pays: 0,
        dtype: 0,
        discount: '',
        vdate: '',
        edate: '',
      },
      vip: [],
    }
  },
  computed: {
    _discount() {
      if (this.data.discount) {
        return (100 - this.data.discount) / 10
      } else {
        return '_'
      }
    },
    // 今天日期時間
    _today() {
      const today = new Date()
      const year = today.getFullYear()
      const month = today.getMonth() + 1
      const day = today.getDate()
      const hour = today.getHours()
      const minute = today.getMinutes()

      function plusZero(value) {
        if (value < 10) return '0' + value
        else return value
      }

      return `${year}-${plusZero(month)}-${plusZero(day)}T${hour}:${minute}`
    },
    _membership() {
      let obj = this.vip.find((e) => e.smvno === this.data.smvno)

      if (obj) return obj.title
    },
    _vdate: {
      get() {
        let vdate = this.data.vdate.replace(' ', 'T').substr(0, 16)
        return vdate
      },
      set(val) {
        this.data.vdate = val
      },
    },
    _edate: {
      get() {
        let edate = this.data.edate.replace(' ', 'T').substr(0, 16)
        return edate
      },
      set(val) {
        this.data.edate = val
      },
    },
  },
  watch: {
    $route(to, from) {
      this.isNewFunc()
    },
  },
  created() {
    this.isNewFunc()
    this.getVIP()
  },
  methods: {
    isNewFunc() {
      // 判斷是新增部落格 還是 編輯部落格

      if (this.$route.name === 'new-storewide_discount') {
        this.isNew = true
      } else {
        this.isNew = false
        this.getData()
      }
    },
    // 3.5.1.2 全館優惠資料-讀取
    getData() {
      this.$axios({
        url: 'front/store/market/getBuildingUnit.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          smbno: this.$route.params.id,
        },
      }).then((res) => {
        if (res.data.Success) {
          let data = res.data.Data
          if (data.smvno === '' && data.smtype === 0) data.smvno = '不限對象'
          if (data.smvno === '' && data.smtype === 1) data.smvno = '全體會員'
          this.data = data
          this.is_API_loaded = true
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 3.5.1.3 全館優惠資料-新增
    // 3.5.1.4 全館優惠資料-修改
    save(invalid) {
      this.alertAfterCreated = false
      // 如果驗證未通過，return
      if (invalid) return

      if (this.dateTimeValidationMixin(this._vdate, this._edate) === false) return

      const loading = this.$vs.loading({
        target: this.$refs.saveBtn,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      let url = ''

      let data = {
        lang: 0,
        title: this.data.title,
        smtype: this.data.smvno === '不限對象' ? 0 : 1,
        smvno: this.data.smvno === '不限對象' || this.data.smvno === '全體會員' ? '' : this.data.smvno,
        pays: this.data.pays,
        dtype: this.data.dtype,
        discount: this.data.discount,
        vdate: this._vdate + ':00',
        edate: this._edate + ':00',
      }

      if (this.isNew) {
        url = 'front/store/market/setBuildingData.php'
      } else {
        url = 'front/store/market/uBuildingData.php'
        data.smbno = this.$route.params.id
      }

      this.$axios({
        url: url,
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: data,
      }).then((res) => {
        loading.close()
        if (res.data.Success) {
          this.$myVS.openNoti(res.data.Message, '', undefined)

          // 若是新增
          if (this.isNew) {
            this.alertAfterCreated = true // 呈現alert
            this.alertTitle = this.data.title
            // this.$router.replace({
            //   // 跳轉到新頁面
            //   name: 'edit-storewide_discount',
            //   params: {
            //     id: res.data.Data.smbno,
            //   },
            // })
            this.$router.replace({
              name: 'storewide_discounts',
            })
          } else {
            // this.getData()
            this.$router.replace({
              name: 'storewide_discounts',
            })
          }
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 3.5.1.5 全館優惠資料-刪除
    deleteFunc() {
      const loading = this.$vs.loading({
        target: this.$refs.deleteFuncBtn,
        background: 'danger',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/store/market/dBuildingData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          smbno: this.$route.params.id,
        },
      }).then((res) => {
        loading.close() // 關閉按鈕loading
        if (res.data.Success) {
          this.delete_dialog = false // 關閉dialog
          this.$myVS.openNoti(res.data.Message, '', undefined)

          this.$router.replace({
            name: 'storewide_discounts',
          })
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 3.2.2.1 讀取會員等級列表
    getVIP() {
      this.$axios({
        url: 'front/store/mvip/getMVipList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.vip = res.data.Data
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    handleRadio() {
      this.data.discount = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.box {
  @apply flex-grow h-fit bg-white p-4 rounded-xl space-y-6;
}

.list_item {
  @apply ml-0 relative px-4;

  &::before {
    content: '';
    position: absolute;
    width: 7px;
    height: 7px;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100%;
    background: var(--gray-border);
  }
}

::v-deep .vs-alert__title {
  height: 100%;
}

::v-deep .vs-alert__content {
  min-height: 0px;
}
</style>
